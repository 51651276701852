.popup {
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;
}

.popup.visible {
  opacity: 1;
  pointer-events: all;
}

.popup.offline {
  background-color: #f56565; /* Red */
  color: white;
}

.popup.online {
  background-color: #48bb78; /* Green */
  color: white;
}

.popup button {
  background-color: white;
  color: #f56565;
  font-weight: bold;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.popup button:hover {
  background-color: #edf2f7; /* Light gray */
}
