/* Add your CSS styling here */
.search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
  }

  .contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
  
  .toolbar {
    background-color: #e4848b;
    width: 100%;
    font-weight: bold;
    padding: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .toolbar-title {
    color: #fff;
    margin: 0;
  }
  
  .search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .search-container img {
      max-width: 20%;
  }
  
  .search-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
  }
  
  input[type="text"] {
    padding: 8px;
    margin-bottom: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: inherit;
  }

  input[type="text"]:hover{
    border: 1px solid #ccc;
  }
  
  button {
    padding: 8px 12px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-results ul {
    list-style-type: none;
    padding: 0;
  }
  
  .search-results li {
    margin: 4px 0;
    width: 80vw;
  }

  .go-back {
    margin-right: 8px;
    font-size: 18px;
    cursor: pointer;
  }
  