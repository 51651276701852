.notice-dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .iphone {
    width: 90vw;
    height: auto;
    background-color: rgb(255 208 208 / 24%);
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    position: relative;
  }
  
  .camera {
    width: 25px;
    height: 25px;
    background-color: #666;
    border-radius: 50%;
    margin: 5px auto;
  }
  
  .speaker {
    width: 40px;
    height: 10px;
    background-color: #666;
    margin: 0 auto;
  }
  
  .screen {
    /* flex-grow: 1; */
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
  }
  
  .content {
    text-align: center;
  }
  
  .content p {
    font-size: 15px;
    margin-bottom: 20px;
  }
  
  .home-button {
    width: 40px;
    height: 40px;
    background-color: #666;
    border-radius: 50%;
    margin: 0 auto;
  }
  
  .content button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    width: 70vw;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  