.flex-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
  }

  .flex-container-small {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    height: 80%;
    margin-bottom: 30px;
  }

  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .hide-scrollbar::-webkit-scrollbar{
      display: none;
  }
  
  .flex-item {
    /* width: calc(48% - 5px); 5px for the horizontal margin between items */
    margin-bottom: 10px; /* Vertical margin between rows */
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    height: 30vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .flex-item-small {
    /* width: 100%;  */
    margin-bottom: 10px; /* Vertical margin between rows */
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    height: 30vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .flex-item-md {
    width: calc(60% - 5px); /* 5px for the horizontal margin between items */
    margin-bottom: 10px; /* Vertical margin between rows */
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
    height: 30vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }